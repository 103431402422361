import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  IconUser, 
  IconBriefcase, 
  IconHome, 
  IconHeart, 
  IconPaw, 
  IconGlass, 
  IconTree, 
  IconRun, 
  IconPhoto,
  IconMapPin,
  IconCalendar,
  IconSchool,
  IconBuildingCommunity,
  IconEdit,
  IconChevronRight,
  IconCamera,
  IconMessage,
  IconLogout,
  IconX,
  IconCheck,
  IconMoon,
  IconSun
} from '@tabler/icons-react';
import { OnboardingData, getProfileData } from '../../api/onboardingApi';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';

const ProfileSection: React.FC<{ 
  title: string; 
  children: React.ReactNode; 
  onEdit?: () => void;
  isEditing?: boolean;
}> = ({ title, children, onEdit, isEditing }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="card group max-w-2xl mx-auto"
  >
    <div className="flex items-center justify-between mb-4">
      <h3 className="text-xl font-semibold text-gray-900 dark:text-white">{title}</h3>
      {onEdit && (
        <button 
          onClick={onEdit}
          className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-all duration-200"
        >
          <IconEdit className={`w-5 h-5 ${isEditing ? 'text-primary-500' : 'text-gray-400 dark:text-gray-500 hover:text-primary-500'}`} />
        </button>
      )}
    </div>
    {children}
  </motion.div>
);

export const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { isDarkMode, toggleDarkMode } = useTheme();
  const [profileData, setProfileData] = useState<OnboardingData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingSections, setEditingSections] = useState<Record<string, boolean>>({});
  const [editedData, setEditedData] = useState<Partial<OnboardingData>>({});
  const [tempData, setTempData] = useState<Partial<OnboardingData>>({});

  const handleSignOut = () => {
    logout();
    navigate('/signin');
  };

  const toggleEdit = (section: string) => {
    setEditingSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
    if (!editingSections[section]) {
      setTempData(profileData || {});
    }
  };

  const handleSave = async (section: string) => {
    // TODO: Implement API call to save changes
    setEditingSections(prev => ({
      ...prev,
      [section]: false
    }));
    // For now, just update local state
    setProfileData(prev => ({
      ...prev!,
      ...tempData
    }));
  };

  const handleCancel = (section: string) => {
    setEditingSections(prev => ({
      ...prev,
      [section]: false
    }));
    setTempData(profileData || {});
  };

  const handleInputChange = (field: string, value: string) => {
    setTempData(prev => ({ ...prev, [field]: value }));
  };

  const handleOccupationChange = (field: string, value: string) => {
    setTempData(prev => ({
      ...prev,
      occupation: {
        ...prev.occupation,
        [field]: value
      }
    }));
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setLoading(true);
        const data = await getProfileData();
        setProfileData(data);
        setTempData(data);
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load profile data');
        console.error('Error fetching profile data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600 dark:text-gray-400">Loading profile...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="text-center px-4">
          <div className="text-red-500 text-5xl mb-4">⚠️</div>
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-2">Oops! Something went wrong</h2>
          <p className="text-gray-600 dark:text-gray-400 mb-4">{error}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="btn-primary"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (!profileData) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="text-center px-4">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-2">No Profile Data</h2>
          <p className="text-gray-600 dark:text-gray-400">Please complete your profile setup.</p>
        </div>
      </div>
    );
  }

  const calculateAge = (birthDate: string) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    
    return age;
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 transition-colors duration-200">
      {/* Theme Toggle and Sign Out Buttons */}
      <div className="fixed top-6 right-6 z-50 flex items-center gap-4">
        <button
          onClick={toggleDarkMode}
          className="flex items-center gap-2 px-4 py-2.5 text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:bg-gray-50 dark:hover:bg-gray-700 transition-all duration-200 font-medium border border-gray-100 dark:border-gray-700"
          title={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
        >
          {isDarkMode ? <IconSun size={20} /> : <IconMoon size={20} />}
        </button>
        <button
          onClick={handleSignOut}
          className="flex items-center gap-2 px-4 py-2.5 text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:bg-gray-50 dark:hover:bg-gray-700 transition-all duration-200 font-medium border border-gray-100 dark:border-gray-700"
        >
          <IconLogout size={20} className="text-gray-600 dark:text-gray-400" />
          <span>Sign Out</span>
        </button>
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="py-12"
      >
        {/* Main Content */}
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Profile Header */}
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg border border-gray-100 dark:border-gray-700 p-8 mb-8 transition-colors duration-200"
          >
            <div className="flex flex-col sm:flex-row items-center gap-6 sm:gap-8">
              {/* Profile Picture */}
              <div className="relative group">
                <div className="w-32 h-32 sm:w-40 sm:h-40 rounded-full overflow-hidden ring-4 ring-white dark:ring-gray-700 shadow-lg">
                  <img
                    src={profileData.photos?.[0]?.url || '/default-avatar.png'}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                </div>
                <button 
                  className="absolute bottom-2 right-2 p-2.5 bg-white dark:bg-gray-800 rounded-full shadow-lg opacity-0 group-hover:opacity-100 transition-all duration-200 hover:bg-gray-50 dark:hover:bg-gray-700"
                  title="Change profile picture"
                >
                  <IconCamera className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                </button>
              </div>

              {/* Profile Info */}
              <div className="flex-1 text-center sm:text-left">
                <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 dark:text-white mb-2">
                  {profileData.firstName} {profileData.lastName}
                  {profileData.birthDate && (
                    <span className="ml-3 text-2xl sm:text-3xl text-gray-500 dark:text-gray-400">
                      {calculateAge(profileData.birthDate)}
                    </span>
                  )}
                </h1>
                <div className="flex flex-wrap gap-4 justify-center sm:justify-start mt-4">
                  {profileData.occupation && (
                    <span className="inline-flex items-center gap-2 px-4 py-2 bg-blue-50 dark:bg-blue-900/30 text-blue-700 dark:text-blue-300 rounded-full">
                      <IconBriefcase className="w-4 h-4" />
                      <span>
                        {profileData.occupation.jobTitle}
                        {profileData.occupation.company && ` at ${profileData.occupation.company}`}
                      </span>
                    </span>
                  )}
                  {profileData.location && (
                    <span className="inline-flex items-center gap-2 px-4 py-2 bg-purple-50 dark:bg-purple-900/30 text-purple-700 dark:text-purple-300 rounded-full">
                      <IconMapPin className="w-4 h-4" />
                      <span>{profileData.location}</span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </motion.div>

          {/* Profile Sections */}
          <div className="space-y-8">
            {/* About */}
            {profileData.bio && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                className="bg-gradient-to-r from-purple-50 to-pink-50 dark:from-purple-900/20 dark:to-pink-900/20 rounded-2xl shadow-lg p-8 border border-purple-100 dark:border-purple-800/30 transition-colors duration-200"
              >
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-purple-800 dark:text-purple-300">About Me</h2>
                  {!editingSections.about ? (
                    <button
                      onClick={() => toggleEdit('about')}
                      className="p-2 rounded-full hover:bg-white/50 dark:hover:bg-gray-800/50 transition-colors"
                      title="Edit about me"
                    >
                      <IconEdit className="w-5 h-5 text-purple-600 dark:text-purple-400 hover:text-purple-800 dark:hover:text-purple-300" />
                    </button>
                  ) : (
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleCancel('about')}
                        className="p-2 rounded-full hover:bg-white/50 dark:hover:bg-gray-800/50 transition-colors"
                        title="Cancel"
                      >
                        <IconX className="w-5 h-5 text-red-500 hover:text-red-700" />
                      </button>
                      <button
                        onClick={() => handleSave('about')}
                        className="p-2 rounded-full hover:bg-white/50 dark:hover:bg-gray-800/50 transition-colors"
                        title="Save changes"
                      >
                        <IconCheck className="w-5 h-5 text-green-500 hover:text-green-700" />
                      </button>
                    </div>
                  )}
                </div>
                {editingSections.about ? (
                  <textarea
                    value={tempData.bio || ''}
                    onChange={(e) => handleInputChange('bio', e.target.value)}
                    className="w-full p-4 border rounded-xl focus:ring-2 focus:ring-purple-400 focus:border-purple-400 text-lg bg-white/80 dark:bg-gray-800/80 dark:text-white dark:border-purple-800/30"
                    rows={4}
                    placeholder="Tell us about yourself..."
                  />
                ) : (
                  <p className="text-lg text-gray-700 dark:text-gray-300 leading-relaxed">{profileData.bio}</p>
                )}
              </motion.div>
            )}

            {/* Basics */}
            <div className="bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-blue-900/20 dark:to-indigo-900/20 rounded-2xl shadow-lg p-8 border border-blue-100 dark:border-blue-800/30 transition-colors duration-200">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-blue-800 dark:text-blue-300">Basics</h2>
                {!editingSections.basics ? (
                  <button
                    onClick={() => toggleEdit('basics')}
                    className="p-2 rounded-full hover:bg-white/50 dark:hover:bg-gray-800/50 transition-colors"
                    title="Edit basics"
                  >
                    <IconEdit className="w-5 h-5 text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300" />
                  </button>
                ) : (
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleCancel('basics')}
                      className="p-2 rounded-full hover:bg-white/50 dark:hover:bg-gray-800/50 transition-colors"
                      title="Cancel"
                    >
                      <IconX className="w-5 h-5 text-red-500 hover:text-red-700" />
                    </button>
                    <button
                      onClick={() => handleSave('basics')}
                      className="p-2 rounded-full hover:bg-white/50 dark:hover:bg-gray-800/50 transition-colors"
                      title="Save changes"
                    >
                      <IconCheck className="w-5 h-5 text-green-500 hover:text-green-700" />
                    </button>
                  </div>
                )}
              </div>
              {editingSections.basics ? (
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-x-16 gap-y-10">
                  <div className="col-span-1 flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Birth Date</label>
                    <input
                      type="date"
                      value={tempData.birthDate?.split('T')[0] || ''}
                      onChange={(e) => handleInputChange('birthDate', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Location</label>
                    <input
                      type="text"
                      value={tempData.location || ''}
                      onChange={(e) => handleInputChange('location', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="City, Country"
                    />
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Height (cm)</label>
                    <input
                      type="number"
                      value={tempData.height || ''}
                      onChange={(e) => handleInputChange('height', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="Height in centimeters"
                    />
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Languages</label>
                    <input
                      type="text"
                      value={tempData.languages || ''}
                      onChange={(e) => handleInputChange('languages', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="English, Spanish, etc."
                    />
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Relationship Goals</label>
                    <select
                      value={tempData.relationshipGoals || ''}
                      onChange={(e) => handleInputChange('relationshipGoals', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                      <option value="" className="dark:bg-gray-700">Select a goal</option>
                      <option value="Long-term" className="dark:bg-gray-700">Long-term Relationship</option>
                      <option value="Short-term" className="dark:bg-gray-700">Short-term Dating</option>
                      <option value="Marriage" className="dark:bg-gray-700">Marriage</option>
                      <option value="Friendship" className="dark:bg-gray-700">Friendship</option>
                      <option value="Not sure" className="dark:bg-gray-700">Not sure yet</option>
                    </select>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Job Title</label>
                    <input
                      type="text"
                      value={tempData.occupation?.jobTitle || ''}
                      onChange={(e) => handleOccupationChange('jobTitle', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Company</label>
                    <input
                      type="text"
                      value={tempData.occupation?.company || ''}
                      onChange={(e) => handleOccupationChange('company', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Industry</label>
                    <input
                      type="text"
                      value={tempData.occupation?.industry || ''}
                      onChange={(e) => handleOccupationChange('industry', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Education Level</label>
                    <select
                      value={tempData.occupation?.educationLevel || ''}
                      onChange={(e) => handleOccupationChange('educationLevel', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                      <option value="" className="dark:bg-gray-700">Select education level</option>
                      <option value="High School" className="dark:bg-gray-700">High School</option>
                      <option value="Bachelor's" className="dark:bg-gray-700">Bachelor's Degree</option>
                      <option value="Master's" className="dark:bg-gray-700">Master's Degree</option>
                      <option value="PhD" className="dark:bg-gray-700">PhD</option>
                      <option value="Other" className="dark:bg-gray-700">Other</option>
                    </select>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Religion</label>
                    <select
                      value={tempData.religion || ''}
                      onChange={(e) => handleInputChange('religion', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                      <option value="" className="dark:bg-gray-700">Select religion</option>
                      <option value="Christianity" className="dark:bg-gray-700">Christianity</option>
                      <option value="Islam" className="dark:bg-gray-700">Islam</option>
                      <option value="Judaism" className="dark:bg-gray-700">Judaism</option>
                      <option value="Buddhism" className="dark:bg-gray-700">Buddhism</option>
                      <option value="Hinduism" className="dark:bg-gray-700">Hinduism</option>
                      <option value="Atheism" className="dark:bg-gray-700">Atheism</option>
                      <option value="Agnostic" className="dark:bg-gray-700">Agnostic</option>
                      <option value="Other" className="dark:bg-gray-700">Other</option>
                      <option value="Prefer not to say" className="dark:bg-gray-700">Prefer not to say</option>
                    </select>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Smoking</label>
                    <select
                      value={tempData.smoking || ''}
                      onChange={(e) => handleInputChange('smoking', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                      <option value="" className="dark:bg-gray-700">Select option</option>
                      <option value="Never" className="dark:bg-gray-700">Never</option>
                      <option value="Occasionally" className="dark:bg-gray-700">Occasionally</option>
                      <option value="Regularly" className="dark:bg-gray-700">Regularly</option>
                      <option value="Trying to quit" className="dark:bg-gray-700">Trying to quit</option>
                    </select>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Drinking</label>
                    <select
                      value={tempData.drinking || ''}
                      onChange={(e) => handleInputChange('drinking', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                      <option value="" className="dark:bg-gray-700">Select option</option>
                      <option value="Never" className="dark:bg-gray-700">Never</option>
                      <option value="Socially" className="dark:bg-gray-700">Socially</option>
                      <option value="Regularly" className="dark:bg-gray-700">Regularly</option>
                      <option value="Rarely" className="dark:bg-gray-700">Rarely</option>
                    </select>
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-x-16 gap-y-10">
                  {/* Basic Info */}
                  <div className="col-span-1 flex flex-col space-y-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Birth Date</span>
                    <span className="text-lg text-gray-900 dark:text-white">{profileData.birthDate ? new Date(profileData.birthDate).toLocaleDateString() : 'Not specified'}</span>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Location</span>
                    <span className="text-lg text-gray-900 dark:text-white break-words">{profileData.location || 'Not specified'}</span>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Height</span>
                    <span className="text-lg text-gray-900 dark:text-white">{profileData.height ? `${profileData.height} cm` : 'Not specified'}</span>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Languages</span>
                    <span className="text-lg text-gray-900 dark:text-white break-words">{profileData.languages || 'Not specified'}</span>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Relationship Goals</span>
                    <span className="text-lg text-gray-900 dark:text-white">{profileData.relationshipGoals || 'Not specified'}</span>
                  </div>

                  {/* Work & Education */}
                  <div className="col-span-1 flex flex-col space-y-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Job Title</span>
                    <span className="text-lg text-gray-900 dark:text-white break-words">{profileData.occupation?.jobTitle || 'Not specified'}</span>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Company</span>
                    <span className="text-lg text-gray-900 dark:text-white break-words">{profileData.occupation?.company || 'Not specified'}</span>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Industry</span>
                    <span className="text-lg text-gray-900 dark:text-white break-words">{profileData.occupation?.industry || 'Not specified'}</span>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Education Level</span>
                    <span className="text-lg text-gray-900 dark:text-white">{profileData.occupation?.educationLevel || 'Not specified'}</span>
                  </div>

                  {/* Lifestyle */}
                  <div className="col-span-1 flex flex-col space-y-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Religion</span>
                    <span className="text-lg text-gray-900 dark:text-white">{profileData.religion || 'Not specified'}</span>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Smoking</span>
                    <span className="text-lg text-gray-900 dark:text-white">{profileData.smoking || 'Not specified'}</span>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Drinking</span>
                    <span className="text-lg text-gray-900 dark:text-white">{profileData.drinking || 'Not specified'}</span>
                  </div>
                </div>
              )}
            </div>

            {/* Interests */}
            {profileData.interests && profileData.interests.length > 0 && (
              <div className="bg-gradient-to-r from-teal-50 to-emerald-50 dark:from-teal-900/20 dark:to-emerald-900/20 rounded-2xl shadow-lg p-8 border border-teal-100 dark:border-teal-800/30 transition-colors duration-200">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-teal-800 dark:text-teal-300">Interests & Hobbies</h2>
                  {!editingSections.interests ? (
                    <button
                      onClick={() => toggleEdit('interests')}
                      className="p-2 rounded-full hover:bg-white/50 dark:hover:bg-gray-800/50 transition-colors"
                      title="Edit interests"
                    >
                      <IconEdit className="w-5 h-5 text-teal-600 dark:text-teal-400 hover:text-teal-800 dark:hover:text-teal-300" />
                    </button>
                  ) : (
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleCancel('interests')}
                        className="p-2 rounded-full hover:bg-white/50 dark:hover:bg-gray-800/50 transition-colors"
                        title="Cancel"
                      >
                        <IconX className="w-5 h-5 text-red-500 hover:text-red-700" />
                      </button>
                      <button
                        onClick={() => handleSave('interests')}
                        className="p-2 rounded-full hover:bg-white/50 dark:hover:bg-gray-800/50 transition-colors"
                        title="Save changes"
                      >
                        <IconCheck className="w-5 h-5 text-green-500 hover:text-green-700" />
                      </button>
                    </div>
                  )}
                </div>
                {editingSections.interests ? (
                  <div>
                    <div className="flex flex-wrap gap-4 mb-4">
                      {['Sports', 'Music', 'Movies', 'Travel', 'Food', 'Gaming', 'Reading', 'Hiking', 'Photography'].map((interest) => (
                        <button
                          key={interest}
                          onClick={() => {
                            const newInterests = [...(tempData.interests || [])];
                            if (newInterests.includes(interest)) {
                              newInterests.splice(newInterests.indexOf(interest), 1);
                            } else {
                              newInterests.push(interest);
                            }
                            setTempData(prev => ({ ...prev, interests: newInterests }));
                          }}
                          className={`px-8 py-3 rounded-full text-base font-medium transition-colors ${
                            tempData.interests?.includes(interest)
                              ? 'bg-teal-500 text-white dark:bg-teal-800 dark:text-white'
                              : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
                          }`}
                        >
                          {interest}
                        </button>
                      ))}
                    </div>
                    <input
                      type="text"
                      placeholder="Add custom interest and press Enter"
                      value={tempData.customInterest || ''}
                      onChange={(e) => setTempData(prev => ({ ...prev, customInterest: e.target.value }))}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && e.currentTarget.value) {
                          const newInterests = [...(tempData.interests || [])];
                          newInterests.push(e.currentTarget.value);
                          setTempData(prev => ({ ...prev, interests: newInterests, customInterest: '' }));
                        }
                      }}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                    />
                  </div>
                ) : (
                  <div className="flex flex-wrap gap-4">
                    {profileData.interests?.map((interest) => (
                      <span
                        key={interest}
                        className="px-8 py-3 bg-teal-100 dark:bg-teal-900/20 text-teal-800 dark:text-teal-300 rounded-full text-base font-medium hover:bg-teal-200 dark:hover:bg-teal-800/30 transition-colors"
                      >
                        {interest}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};