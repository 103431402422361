import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateInput } from '@mantine/dates';
import { IconChevronLeft, IconChevronRight, IconCheck, IconCalendar } from '@tabler/icons-react';
import styles from './Onboarding.module.css';
import { API_BASE } from '../../config/api';
import { completeOnboarding, uploadProfilePhoto } from '../../api/onboardingApi';

interface FormData {
  firstName: string;
  lastName: string;
  birthDate: Date | null;
  gender: string;
  interestedIn: string[];
  bio: string;
  location: string;
  interests: string[];
  photos: File[];
  lookingFor: string;
  occupation: {
    jobTitle: string;
    company: string;
    industry: string;
    educationLevel: string;
  };
  lifestyle: {
    drinking: string;
    smoking: string;
    exercise: string;
    diet: string;
  };
  preferences: {
    wantChildren: string;
    hasChildren: string;
    hasAnimals: string;
    likeAnimals: string[];
    relationshipType: string;
    dealBreakers: string[];
  };
}

type FormField = keyof FormData;
type FormValue = string | string[] | Date | null | File[];
type NestedFormField = keyof FormData['occupation'] | keyof FormData['lifestyle'] | keyof FormData['preferences'];

const INITIAL_FORM_DATA: FormData = {
  firstName: '',
  lastName: '',
  birthDate: null,
  gender: '',
  interestedIn: [],
  bio: '',
  location: '',
  interests: [],
  photos: [],
  lookingFor: '',
  occupation: {
    jobTitle: '',
    company: '',
    industry: '',
    educationLevel: '',
  },
  lifestyle: {
    drinking: '',
    smoking: '',
    exercise: '',
    diet: '',
  },
  preferences: {
    wantChildren: '',
    hasChildren: '',
    hasAnimals: '',
    likeAnimals: [],
    relationshipType: '',
    dealBreakers: [],
  },
};

const INTERESTS = [
  'Travel', 'Music', 'Sports', 'Reading', 'Gaming',
  'Cooking', 'Art', 'Photography', 'Dancing', 'Movies',
  'Technology', 'Fashion', 'Fitness', 'Food', 'Nature',
  'Pets', 'Writing', 'Languages', 'Yoga', 'Adventure'
] as const;

const EDUCATION_LEVELS = [
  'High School',
  'Some College',
  'Associate Degree',
  'Bachelor\'s Degree',
  'Master\'s Degree',
  'Doctorate',
  'Professional Degree',
  'Other'
] as const;

const ANIMALS = [
  'Dogs',
  'Cats',
  'Birds',
  'Fish',
  'Reptiles',
  'Other'
] as const;

const DEAL_BREAKERS = [
  'Smoking',
  'Drinking',
  'Different Political Views',
  'Different Religious Views',
  'Long Distance',
  'Has Children',
  'Doesn\'t Want Children',
  'Not Pet Friendly',
  'Different Education Level',
  'Different Life Goals'
] as const;

const API_BASE = import.meta.env.VITE_API_URL || 'http://localhost:3002';

export const OnboardingSteps = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [stepValidation, setStepValidation] = useState<boolean[]>([]);
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);

  const validateStep = (stepIndex: number): boolean => {
    switch (stepIndex) {
      case 0: // Basic Information
        return !!(formData.firstName && formData.lastName && formData.birthDate && formData.location && formData.gender);
      case 1: // Career & Education
        return !!(formData.occupation.jobTitle && formData.occupation.educationLevel);
      case 2: // Lifestyle
        return !!(formData.lifestyle.drinking && formData.lifestyle.smoking && 
                 formData.lifestyle.exercise && formData.lifestyle.diet);
      case 3: // Preferences
        return !!(formData.preferences.wantChildren && formData.preferences.hasChildren && 
                 formData.preferences.relationshipType);
      case 4: // About You
        return !!(formData.bio && formData.interests.length >= 3);
      case 5: // Photos
        return formData.photos.length >= 1;
      default:
        return false;
    }
  };

  useEffect(() => {
    const validations = steps.map((_, index) => validateStep(index));
    setStepValidation(validations);
  }, [formData]);

  const handleInputChange = (field: FormField, value: FormValue): void => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleNestedInputChange = (
    category: 'occupation' | 'lifestyle' | 'preferences',
    field: NestedFormField,
    value: string
  ): void => {
    setFormData(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [field]: value,
      },
    }));
  };

  const handlePhotoUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const files = Array.from(event.target.files || []);
    if (files.length > 0) {
      const validFiles = files.filter(file => {
        const isImage = file.type.startsWith('image/');
        const isUnderLimit = file.size <= 5 * 1024 * 1024; // 5MB limit
        return isImage && isUnderLimit;
      });

      setFormData(prev => ({
        ...prev,
        photos: [...prev.photos, ...validFiles].slice(0, 6) // Limit to 6 photos
      }));
    }
  };

  const removePhoto = (index: number): void => {
    setFormData(prev => ({
      ...prev,
      photos: prev.photos.filter((_, i) => i !== index)
    }));
  };

  const toggleArrayItem = (
    category: 'interests' | 'interestedIn' | 'preferences',
    field: string,
    item: string
  ): void => {
    if (category === 'interests' || category === 'interestedIn') {
      setFormData(prev => ({
        ...prev,
        [category]: prev[category].includes(item)
          ? prev[category].filter(i => i !== item)
          : [...prev[category], item]
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        preferences: {
          ...prev.preferences,
          [field]: Array.isArray(prev.preferences[field as keyof typeof prev.preferences])
            ? prev.preferences[field as keyof typeof prev.preferences].includes(item)
              ? (prev.preferences[field as keyof typeof prev.preferences] as string[]).filter(i => i !== item)
              : [...(prev.preferences[field as keyof typeof prev.preferences] as string[]), item]
            : [item]
        }
      }));
    }
  };

  const handleSubmit = async () => {
    if (currentStep === steps.length - 1) {
      try {
        // First, ensure all photos are uploaded
        const photoUrls = await Promise.all(
          formData.photos.map(async (photo) => {
            try {
              return await uploadProfilePhoto(photo);
            } catch (error) {
              console.error('Error uploading photo:', error);
              throw new Error('Failed to upload photo');
            }
          })
        );

        // Prepare onboarding data with uploaded photo URLs
        const onboardingData = {
          ...formData,
          photos: photoUrls,
          interests: formData.interests || [],
          interestedIn: formData.interestedIn || [],
          lookingFor: formData.lookingFor || 'Relationship',
          dealBreakers: formData.dealBreakers || []
        };

        // Complete onboarding using the API
        await completeOnboarding(onboardingData);

        // Redirect to profile page on success
        navigate('/profile');
      } catch (error) {
        console.error('Error completing onboarding:', error);
        alert(error instanceof Error ? error.message : 'Failed to complete onboarding');
      }
    } else {
      // Move to next step if not on last step
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep(prev => prev - 1);
  };

  const getStepStatus = (index: number) => {
    if (index === currentStep) return 'current';
    if (stepValidation[index]) return 'completed';
    return 'pending';
  };

  const steps = [
    {
      title: 'Basic Information',
      content: (
        <div className={styles.stepContent}>
          <div className={styles.formGroup}>
            <label>First Name *</label>
            <input
              type="text"
              value={formData.firstName}
              onChange={(e) => handleInputChange('firstName', e.target.value)}
              placeholder="Enter your first name"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>Last Name *</label>
            <input
              type="text"
              value={formData.lastName}
              onChange={(e) => handleInputChange('lastName', e.target.value)}
              placeholder="Enter your last name"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>Birth Date *</label>
            <div className={styles.dateInput}>
              <IconCalendar size={20} />
              <DateInput
                value={formData.birthDate}
                onChange={(date) => handleInputChange('birthDate', date)}
                placeholder="Select your birth date"
                required
                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
                clearable={false}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label>Gender *</label>
            <div className={styles.radioGroup}>
              {['Male', 'Female', 'Non-binary', 'Prefer not to say'].map((option) => (
                <label key={option} className={styles.radioOption}>
                  <input
                    type="radio"
                    name="gender"
                    value={option}
                    checked={formData.gender === option}
                    onChange={(e) => handleInputChange('gender', e.target.value)}
                  />
                  {option}
                </label>
              ))}
            </div>
          </div>
          <div className={styles.formGroup}>
            <label>Location *</label>
            <input
              type="text"
              value={formData.location}
              onChange={(e) => handleInputChange('location', e.target.value)}
              placeholder="Enter your city, country"
              required
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Career & Education',
      content: (
        <div className={styles.stepContent}>
          <div className={styles.formGroup}>
            <label>Job Title *</label>
            <input
              type="text"
              value={formData.occupation.jobTitle}
              onChange={(e) => handleNestedInputChange('occupation', 'jobTitle', e.target.value)}
              placeholder="What do you do?"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>Company</label>
            <input
              type="text"
              value={formData.occupation.company}
              onChange={(e) => handleNestedInputChange('occupation', 'company', e.target.value)}
              placeholder="Where do you work?"
            />
          </div>
          <div className={styles.formGroup}>
            <label>Industry</label>
            <input
              type="text"
              value={formData.occupation.industry}
              onChange={(e) => handleNestedInputChange('occupation', 'industry', e.target.value)}
              placeholder="What industry do you work in?"
            />
          </div>
          <div className={styles.formGroup}>
            <label>Education Level *</label>
            <select
              value={formData.occupation.educationLevel}
              onChange={(e) => handleNestedInputChange('occupation', 'educationLevel', e.target.value)}
              required
            >
              <option value="">Select Education Level</option>
              {EDUCATION_LEVELS.map((level) => (
                <option key={level} value={level}>{level}</option>
              ))}
            </select>
          </div>
        </div>
      ),
    },
    {
      title: 'Lifestyle',
      content: (
        <div className={styles.stepContent}>
          <div className={styles.formGroup}>
            <label>Drinking</label>
            <select
              value={formData.lifestyle.drinking}
              onChange={(e) => handleNestedInputChange('lifestyle', 'drinking', e.target.value)}
            >
              <option value="">Select Option</option>
              <option value="never">Never</option>
              <option value="rarely">Rarely</option>
              <option value="socially">Socially</option>
              <option value="regularly">Regularly</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>Smoking</label>
            <select
              value={formData.lifestyle.smoking}
              onChange={(e) => handleNestedInputChange('lifestyle', 'smoking', e.target.value)}
            >
              <option value="">Select Option</option>
              <option value="never">Never</option>
              <option value="occasionally">Occasionally</option>
              <option value="regularly">Regularly</option>
              <option value="trying-to-quit">Trying to Quit</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>Exercise</label>
            <select
              value={formData.lifestyle.exercise}
              onChange={(e) => handleNestedInputChange('lifestyle', 'exercise', e.target.value)}
            >
              <option value="">Select Option</option>
              <option value="never">Never</option>
              <option value="sometimes">Sometimes</option>
              <option value="regularly">Regularly</option>
              <option value="daily">Daily</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>Diet</label>
            <select
              value={formData.lifestyle.diet}
              onChange={(e) => handleNestedInputChange('lifestyle', 'diet', e.target.value)}
            >
              <option value="">Select Option</option>
              <option value="anything">Everything</option>
              <option value="vegetarian">Vegetarian</option>
              <option value="vegan">Vegan</option>
              <option value="kosher">Kosher</option>
              <option value="halal">Halal</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>
      ),
    },
    {
      title: 'Preferences & Deal Breakers',
      content: (
        <div className={styles.stepContent}>
          <div className={styles.formGroup}>
            <label>Do you want children?</label>
            <select
              value={formData.preferences.wantChildren}
              onChange={(e) => handleNestedInputChange('preferences', 'wantChildren', e.target.value)}
            >
              <option value="">Select Option</option>
              <option value="want">Want Children</option>
              <option value="dont-want">Don't Want Children</option>
              <option value="not-sure">Not Sure Yet</option>
              <option value="open">Open to Children</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>Do you have children?</label>
            <select
              value={formData.preferences.hasChildren}
              onChange={(e) => handleNestedInputChange('preferences', 'hasChildren', e.target.value)}
            >
              <option value="">Select Option</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>Do you have pets?</label>
            <select
              value={formData.preferences.hasAnimals}
              onChange={(e) => handleNestedInputChange('preferences', 'hasAnimals', e.target.value)}
            >
              <option value="">Select Option</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>What pets do you like?</label>
            <div className={styles.checkboxGroup}>
              {ANIMALS.map((animal) => (
                <label key={animal} className={styles.checkbox}>
                  <input
                    type="checkbox"
                    checked={formData.preferences.likeAnimals.includes(animal)}
                    onChange={() => toggleArrayItem('preferences', 'likeAnimals', animal)}
                  />
                  {animal}
                </label>
              ))}
            </div>
          </div>
          <div className={styles.formGroup}>
            <label>What type of relationship are you looking for?</label>
            <select
              value={formData.preferences.relationshipType}
              onChange={(e) => handleNestedInputChange('preferences', 'relationshipType', e.target.value)}
            >
              <option value="">Select Option</option>
              <option value="casual">Casual Dating</option>
              <option value="serious">Serious Relationship</option>
              <option value="long-term">Long Term Partner</option>
              <option value="marriage">Marriage</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>Deal Breakers</label>
            <div className={styles.checkboxGroup}>
              {DEAL_BREAKERS.map((dealBreaker) => (
                <label key={dealBreaker} className={styles.checkbox}>
                  <input
                    type="checkbox"
                    checked={formData.preferences.dealBreakers.includes(dealBreaker)}
                    onChange={() => toggleArrayItem('preferences', 'dealBreakers', dealBreaker)}
                  />
                  {dealBreaker}
                </label>
              ))}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'About You',
      content: (
        <div className={styles.stepContent}>
          <div className={styles.formGroup}>
            <label>Bio *</label>
            <textarea
              value={formData.bio}
              onChange={(e) => handleInputChange('bio', e.target.value)}
              placeholder="Tell us about yourself"
              rows={4}
              required
            />
          </div>
          <div 
            className={`${styles.formGroup} ${
              formData.interests.length >= 3 
                ? styles.hasSuccess 
                : formData.interests.length > 0 
                ? styles.hasError 
                : ''
            }`}
          >
            <label>Interests (Select at least 3) *</label>
            <div className={styles.interestTags}>
              {INTERESTS.map((interest) => (
                <div
                  key={interest}
                  className={`${styles.tag} ${formData.interests.includes(interest) ? styles.selected : ''}`}
                  onClick={() => toggleArrayItem('interests', '', interest)}
                >
                  {interest}
                </div>
              ))}
            </div>
            <div className={styles.helperText}>
              {formData.interests.length} / 3 interests selected
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Photos',
      content: (
        <div className={styles.stepContent}>
          <div className={styles.formGroup}>
            <label>Upload Photos</label>
            <div className={styles.photoUpload}>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handlePhotoUpload}
                style={{ display: 'none' }}
                id="photo-upload"
              />
              <label htmlFor="photo-upload">
                Click to upload photos or drag them here
              </label>
            </div>
            <div className={styles.photoPreview}>
              {formData.photos.map((photo, index) => (
                <div key={index} className={styles.photoPreviewItem}>
                  <img src={URL.createObjectURL(photo)} alt={`Preview ${index + 1}`} />
                  <button
                    className={styles.removePhoto}
                    onClick={() => removePhoto(index)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.onboardingContainer}>
      <div className={styles.onboardingCard}>
        <div className={styles.stepIndicator}>
          {steps.map((_, index) => (
            <div
              key={index}
              className={`${styles.step} ${getStepStatus(index)}`}
              onClick={() => stepValidation[index] && setCurrentStep(index)}
            >
              {stepValidation[index] && <IconCheck size={12} />}
            </div>
          ))}
        </div>
        <h2 className={styles.stepTitle}>{steps[currentStep].title}</h2>
        {steps[currentStep].content}
        <div className={styles.buttonGroup}>
          {currentStep > 0 && (
            <button onClick={handleBack} className={styles.backButton}>
              <IconChevronLeft size={20} />
              Back
            </button>
          )}
          <button 
            onClick={handleSubmit} 
            className={styles.nextButton}
            disabled={!validateStep(currentStep)}
          >
            {currentStep === steps.length - 1 ? (
              <>
                Finish
                <IconCheck size={20} />
              </>
            ) : (
              <>
                Next
                <IconChevronRight size={20} />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
