import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './Landing.module.css';

export const Landing: FC = () => {
  return (
    <div className={styles.landing}>
      <nav className={styles.navbar}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <h1>Datify</h1>
          </div>
          <div className={styles.navLinks}>
            <Link to="/signin" className={styles.loginLink}>Sign In</Link>
            <Link to="/signup" className={styles.ctaButton}>Get Started</Link>
          </div>
        </div>
      </nav>

      <section className={styles.hero}>
        <div className={styles.container}>
          <div className={styles.heroContent}>
            <h1>Find Your Perfect Match</h1>
            <p>Join thousands of singles who have found meaningful connections through Datify. 
               Our intelligent matching system helps you find the right person.</p>
            <Link to="/signup" className={styles.ctaButton}>Start Your Journey</Link>
          </div>
          <div className={styles.heroImage}>
            {/* Add your hero image here */}
          </div>
        </div>
      </section>

      <section className={styles.features}>
        <div className={styles.container}>
          <h2>Why Choose Datify?</h2>
          <div className={styles.featureGrid}>
            <div className={styles.featureCard}>
              <div className={styles.featureIcon}>❤️</div>
              <h3>Smart Matching</h3>
              <p>Our AI-powered algorithm finds compatible matches based on your preferences</p>
            </div>
            <div className={styles.featureCard}>
              <div className={styles.featureIcon}>🛡️</div>
              <h3>Safe & Secure</h3>
              <p>Your privacy and security are our top priority</p>
            </div>
            <div className={styles.featureCard}>
              <div className={styles.featureIcon}>💬</div>
              <h3>Real Connections</h3>
              <p>Connect through chat, video calls, and meaningful conversations</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
