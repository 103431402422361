import React from 'react';
import { MantineProvider, createTheme } from '@mantine/core';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';

// Import existing pages
import { Landing } from './pages/Landing/Landing';
import { SignIn } from './pages/Auth/SignIn';
import { SignUp } from './pages/Auth/SignUp';
import { OnboardingSteps } from './pages/Onboarding/OnboardingSteps';
import { ProfilePage } from './pages/Profile/Profile';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';

const theme = createTheme({
  primaryColor: 'pink',
  colors: {
    pink: [
      '#FFF0F6',
      '#FFDEEB',
      '#FCC2D7',
      '#FAA2C1',
      '#F783AC',
      '#F06595',
      '#E64980',
      '#D6336C',
      '#C2255C',
      '#A61E4D',
    ],
  },
  fontFamily: 'Inter, sans-serif',
  headings: {
    fontFamily: 'Playfair Display, serif',
  },
  defaultRadius: 'md',
  components: {
    Button: {
      defaultProps: {
        size: 'md',
      },
      styles: {
        root: {
          fontWeight: 600,
        },
      },
    },
  },
});

function App() {
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider>
          <MantineProvider theme={theme}>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/onboarding" element={<OnboardingSteps />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </MantineProvider>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
