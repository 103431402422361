import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:3002';

export interface OnboardingData {
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  interestedIn: string[];
  bio: string;
  location: string;
  interests: string[];
  photos: string[];
  lookingFor: string;
  jobTitle?: string;
  company?: string;
  drinking?: string;
  smoking?: string;
  exercise?: string;
  diet?: string;
  wantChildren?: string;
  relationshipType?: string;
  dealBreakers?: string[];
}

export const getProfileData = async (): Promise<OnboardingData> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    console.log('Fetching profile data...');
    const response = await axios.get(`${API_URL}/api/profile`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log('Profile data received:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error);
    if (axios.isAxiosError(error)) {
      const message = error.response?.data?.message || error.message;
      throw new Error(`Failed to fetch profile data: ${message}`);
    }
    throw error;
  }
};

export const updateProfileData = async (data: Partial<OnboardingData>): Promise<OnboardingData> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    const response = await axios.put(`${API_URL}/api/users/profile`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to update profile data');
  }
};

export const uploadProfilePhoto = async (file: File): Promise<string> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  const formData = new FormData();
  formData.append('photo', file);

  try {
    const response = await axios.post(`${API_URL}/api/users/upload-photo`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.url;
  } catch (error) {
    throw new Error('Failed to upload photo');
  }
};

export const completeOnboarding = async (data: OnboardingData): Promise<OnboardingData> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    const response = await axios.post(`${API_URL}/api/users/complete-onboarding`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error completing onboarding:', error);
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Failed to complete onboarding');
    }
    throw error;
  }
};
